.bg-primary {
  background: #40ff7a;
}

.bg-secondary {
  background: #ff008c;
}

.bg-grey {
  background: #e5e5e5;
}

.text-primary {
  color: #40ff7a;
}

.border-primary {
  border-color: #40ff7a;
}

.text-secondary {
  color: #ff008c;
}

.border-secondary {
  border-color: #ff008c;
}

.progress-line-white {
  width: 0px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-radius: 1px;
  height: 45px;
}

.progress-line-green {
  width: 0px;
  border-left: 1px solid #40ff7a;
  border-right: 1px solid #40ff7a;
  border-radius: 1px;
  height: 45px;
}
