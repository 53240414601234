/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;500;600;700&display=swap");

.font-overpass {
  font-family: "Overpass", sans-serif
}

.customer-landing-text {
  font-family: "Overpass", sans-serif;
}

.polestar-dark {
  background-color: rgb(243,244,244);
}

/* Polestar Font approximation */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.polestar-font {
  font-family: "Poppins", sans-serif
}

.polestar-bg-img {
  background-image: url("./assets/polestar-background.jpg");
  background-color: #d0d0d0;
  background-repeat: no-repeat;
  background-size: contain;
}

.polestar-bg-colour {
  background-color: #d0d0d0;
}

.bg-beige {
  background-color: #d0d0d0;
}

.polestar-width {
  width: 720px;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.emulate-input:focus {
  outline:none; 
  border:1px solid #4D90FE;
  -webkit-box-shadow: 0px 0px 5px  #4D90FE;
  box-shadow: 0px 0px 5px  #4D90FE;
}